var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('scrollable-container',[_c('placeholder',{directives:[{name:"show",rawName:"v-show",value:(_vm.state === _vm.reportEnum.requestAwaiting),expression:"state === reportEnum.requestAwaiting"}],attrs:{"text":_vm.text},on:{"buttonClick":_vm.buildReport}}),_c('loader',{directives:[{name:"show",rawName:"v-show",value:(_vm.state === _vm.reportEnum.tableLoading),expression:"state === reportEnum.tableLoading"}]}),_c('container',{directives:[{name:"show",rawName:"v-show",value:(_vm.state === _vm.reportEnum.tableReady),expression:"state === reportEnum.tableReady"}],class:[
    ( _obj = {}, _obj[_vm.$style.message] = _vm.state === _vm.reportEnum.requestAwaiting, _obj ),
    _vm.$style.scrollableContent ],attrs:{"id":"printable"}},[(_vm.tableData.length === 0)?_c('div',{class:_vm.$style.emptyResult},[_vm._v(" Нет данных за указанный период ")]):_vm._e(),_vm._l((_vm.tableData),function(user,index){
  var _obj;
return _c('div',{key:user.name,class:_vm.$style.user},[_c('div',{class:_vm.$style.userStickyToggle,on:{"click":function($event){return _vm.toggle(user)}}},[_c('div',{class:[
          _vm.$style.expandButton,
          ( _obj = {}, _obj[_vm.$style.expanded] = user.isExpanded, _obj ) ]}),_c('div',{class:_vm.$style.name},[_vm._v(" "+_vm._s(user.name)+" ")])]),_vm._l((user.dates),function(date){return _c('productivity-table',{directives:[{name:"show",rawName:"v-show",value:(user.isExpanded),expression:"user.isExpanded"}],key:user.name + date.date + index,class:_vm.$style.userData,attrs:{"date":date}})})],2)})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }