<template>
  <div>
    <div :class="$style.toggle" @click="toggle">
      <div :class="[$style.expandButton, { [$style.expanded]: isExpanded }]" />
      <div :class="$style.date">
        {{ date.date }}
      </div>
    </div>
    <table v-show="isExpanded" :class="$style.table">
      <thead>
        <tr :class="$style.header">
          <td
            v-for="(column, number) in columns"
            :key="column.name + date.date + number"
            :style="{ minWidth: column.width }"
          >
            {{ column.name }}
          </td>
        </tr>
      </thead>
      <tbody>
        <tr :class="$style.item">
          <td
            v-for="(column, number) in columns"
            :key="date[column.field] + date.date + number"
          >
            {{ getDataField(date[column.field], column.field) }}
          </td>
        </tr>
      </tbody>
    </table>
    <timeline v-show="isExpanded" :date="date" :columns="columns" />
  </div>
</template>

<script>
import Timeline from '@/components/common/bars/timeline.vue';
import { getDataField } from '@/views/reports/users/worktime/table-field';

export default {
  components: {
    Timeline,
  },
  props: ['date'],
  data: () => ({
    name: 'ProductivityTable',
    isExpanded: true,
    columns: [
      { name: 'Включен', width: '58px', field: 'workTime', sort: 2 },
      { name: 'Активность', width: '71px', field: 'activeTime', sort: 0 },
      { name: 'Простой', width: '56px', field: 'idleTime', sort: 0 },
      { name: '% активности', width: '70px', field: 'activePercent', sort: 0 },
      { name: 'Начало', width: '50px', field: 'dayStart', sort: 0 },
      { name: 'Окончание', width: '71px', field: 'dayFinish', sort: 0 },
      { name: 'График', width: '62px', field: 'isWorkingDay', sort: 0 },
      { name: 'По плану', width: '41px', field: 'plan', sort: 0 },
      { name: 'Переработка', width: '81px', field: 'overwork', sort: 0 },
      { name: 'Продуктивно', width: '82px', field: 'productiveTime', sort: 0 },
      {
        name: 'Непродуктивно',
        width: '95px',
        field: 'unproductiveTime',
        sort: 0,
      },
      { name: 'Нейтрально', width: '75px', field: 'neutralTime', sort: 0 },
    ],
  }),
  methods: {
    toggle() {
      this.isExpanded = !this.isExpanded;
    },
    getDataField(data, field) {
      return getDataField(data, field);
    },
  },
};
</script>

<style lang="scss" module>
.table {
  width: 100%;
  border-collapse: collapse;
  font-weight: 400;
  word-break: break-word;
  border: 1px solid $borderColor;
  padding-bottom: 20px;
}
.header {
  background: #dbf1ff;
  height: 31px;
  position: sticky;
  top: 22px;
  z-index: 2;
  td {
    border: 1px solid $borderColor;
    align-items: center;
    text-align: center;
    color: #232326;
    letter-spacing: -0.08px;
    font-size: 12px;
    padding: 4px;
    vertical-align: middle;
    cursor: default;
  }
}

.expandButton {
  flex-shrink: 0;
  margin-left: -27px;
  margin-right: 11px;
  width: 14px;
  height: 14px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $branchColor;
  background: $white;
  z-index: 2;

  &::before,
  &::after {
    content: '';
    background-color: $blue;
    width: 8px;
    height: 2px;
    position: absolute;
    transition: 0.25s ease-out;
  }

  &::before {
    transform: rotate(90deg);
  }

  &::after {
    transform: rotate(180deg);
  }

  &.expanded {
    &::before {
      transform: rotate(0);
    }

    &::after {
      transform: rotate(0);
    }
  }
}
.date {
  background-color: $light-gray;
  letter-spacing: -0.24px;
  width: 90px;
  height: 24px;
  font-family: inherit;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  padding: 5px 10px;
  display: inline-flex;
  align-items: center;
  color: #4b4b4b;
  margin-top: 10px;
  margin-bottom: 5px;
}

.toggle {
  cursor: pointer;
}

.item {
  height: 29px;

  td {
    border: 1px solid $borderColor;
    align-items: center;
    letter-spacing: -0.24px;
    color: $black;
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    padding: 5px;
    vertical-align: middle;
  }
}
</style>
