<template>
  <div>
    <table :class="$style.ruler">
      <tr class="ruler">
        <td
          v-for="number in hoursNumber.hours"
          :key="number"
          :class="$style.hour"
        >
          {{ getRulerTime(number) }}
        </td>
      </tr>
    </table>
    <div>
      <div :class="$style.timeline">
        <svg :class="$style.svg">
          <g v-for="(event, index) in timeline" :key="event.start + index">
            <text
              v-if="index === 0"
              :class="$style.text"
              :x="event.start - 6 + '%'"
              y="15"
            >
              {{ getTime(date.dayStart) }}
            </text>
            <rect
              :width="event.width + '%'"
              :x="event.start + '%'"
              :class="[
                $style.bar,
                {
                  [$style.red]: event.productivity === 0,
                },
                {
                  [$style.green]: event.productivity === 1,
                },
                {
                  [$style.yellow]: event.productivity === 2,
                },
                {
                  [$style.white]: event.productivity === 4,
                },
              ]"
            />
            <title :class="$style.tooltiptext">
              {{ convertSecondsToDays(event.width / oneSecondPercent) }}
            </title>
            <text
              v-if="index === timeline.length - 1"
              :class="$style.text"
              :x="event.start + event.width + 0.5 + '%'"
              y="15"
            >
              {{ getTime(date.dayFinish) }}
            </text>
          </g>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import { getTime, convertSecondsToDays } from '@/helpers/format-date';

export default {
  props: ['columns', 'date'],
  data: () => ({
    timeline: [],
  }),
  computed: {
    hoursNumber() {
      const startTime = new Date(this.date.dayStart).getHours() - 1;
      const finishTime = new Date(this.date.dayFinish).getHours() + 1;
      const hoursNumber = finishTime - startTime + 1;
      const hoursArray = [];
      for (let i = startTime; i <= finishTime; i++) {
        hoursArray.push(i);
      }
      return {
        start: startTime,
        finish: finishTime,
        number: hoursNumber,
        hours: hoursArray,
      };
    },
    oneSecondPercent() {
      return 1 / (this.hoursNumber.number * 36);
    },
  },
  created() {
    this.fetch();
  },
  methods: {
    getRulerTime(hours) {
      if (hours === 24) return '00:00';
      if (hours === -1) return '23:00';
      return `${hours}:00`;
    },
    convertSecondsToDays(secNum) {
      return convertSecondsToDays(secNum);
    },
    getTime(date) {
      return getTime(date);
    },

    getStartTime(date) {
      const startTime = new Date(date);
      const startHour = startTime.getHours() - this.hoursNumber.start;
      const startMinute = startTime.getMinutes();
      const startSecond = startTime.getSeconds();

      return (
        (startHour * 3600 + startMinute * 60 + startSecond)
        * this.oneSecondPercent
      );
    },

    fetch() {
      if (this.date.timeline.length === 0) return [];

      const timelineArray = this.date.timeline;
      for (let i = 0; i < timelineArray.length; i++) {
        if (timelineArray[i].duration < 0) timelineArray[i].duration = 0;
        this.timeline.push({
          width: timelineArray[i].duration * this.oneSecondPercent,
          start: this.getStartTime(timelineArray[i].eventStart),
          productivity: timelineArray[i].productivity,
        });
      }
      return this.timeline;
    },
  },
};
</script>

<style lang="scss" module>
.ruler {
  width: 100%;
  margin-bottom: 20px;
  table-layout: fixed;
}
.hour {
  border: 1px solid $borderColor;
  height: 60px;
  color: #7e7e7e;
  font-weight: 300;
  font-size: 12px;
  text-align: left;
  padding-left: 7px;
  padding-top: 7px;
}
.timeline {
  margin-top: -52px;
  height: 40px;
  width: 100%;
}
.svg {
  width: 100%;
  height: 23px;
  overflow: visible;
}
.bar {
  height: 23px;
  cursor: pointer;
  &.red {
    fill: #ffb0b0;

    &:hover {
      fill: $red;
    }
  }
  &.green {
    fill: #7cd890;

    &:hover {
      fill: #1f8d37;
    }
  }
  &.yellow {
    fill: #ffecbc;

    &:hover {
      fill: #d49a02;
    }
  }
  &.white {
    fill: #e8e8e8;

    &:hover {
      fill: $branchColor;
    }
  }
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
.text {
  font-size: 12px;
}
</style>
